import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import InsideBanner from '../components/insidebanner'
import { Container, Header } from 'semantic-ui-react'
import photo1 from '../images/MR7T2976.jpg' 

const GetConnected = () => (
  <Layout location="connected">
    <SEO title="Get Connected" />
    <InsideBanner headline="Get Connected" src="/img/banners/Eisenreich.Phillies-e1523836613569.jpg" />
    <Container style={{ marginBottom: '35px' }} vertical>
      <Header as='h2' content="Get involved to help kids with Tourette thrive" />
      <img src={photo1} alt="Jim signing autographs" align="right" style={{ width: '38%', paddingLeft: '30px'}} />
      <p>There are many ways to connect with the foundation and begin making an impact in the lives of children with Tourette Syndrome.</p>
      <Header as='h3' content="Host Jim" />
      <p>Bring Jim to your school, youth program or company to help motivate and inspire the audience. Or arrange a ballpark outing for your Tourette group with Jim as the guest speaker.</p>
      <p>Jim talks about his life long challenge in dealing with the symptoms of TS and describes how he overcame those challenges to become a Major Leauge baseball player and a World Series Champion.</p>
      <p>To contact us about the possibility of Jim speaking at your event, <a href='mailto:contactus@tourettes.org'>send us an email with the details of your event</a>.</p>
      <Header as='h3' content="Support the Foundation" />
      <p>Your donation to the Jim Eisenreich Foundation will help us reach more kids and families affected by TS.</p>
      <p>Please send your donation to:</p>
      <p>
        Jim Eisenreich Foundation<br/>
        Post Office Box 953<br/>
        Blue Springs, MO 64013
      </p>
      <p>Thank you for supporting our work with children suffering from Tourette Syndrome!!</p>
    </Container>
  </Layout>
)

export default GetConnected
